// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animation-js": () => import("./../../../src/pages/animation.js" /* webpackChunkName: "component---src-pages-animation-js" */),
  "component---src-pages-graphic-js": () => import("./../../../src/pages/graphic.js" /* webpackChunkName: "component---src-pages-graphic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontak-js": () => import("./../../../src/pages/kontak.js" /* webpackChunkName: "component---src-pages-kontak-js" */),
  "component---src-pages-tentang-js": () => import("./../../../src/pages/tentang.js" /* webpackChunkName: "component---src-pages-tentang-js" */),
  "component---src-pages-videography-js": () => import("./../../../src/pages/videography.js" /* webpackChunkName: "component---src-pages-videography-js" */)
}

